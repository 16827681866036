import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import styles from './styles.module.scss'
import image from "../../../assets/images/jpg/parkk_inn.jpg"

export function Welcome(props) {
    return (
        <div className={styles.wrapper}>
            <p className={styles.bold}>Место проведения конференции: </p>
            <p>Конгресс-холл отеля Cosmos Saint-Petersburg Pulkovskaya</p>
            <p>г. Санкт-Петербург, пл. Победы, д.1</p>
            <div className={styles.map}>
                <img src={image} alt=""/>
                <YMaps>
                    <Map defaultState={{center: [59.842725, 30.318410], zoom: 13}} width={"100%"} height={"100%"}>
                        <Placemark geometry={[59.842725, 30.318410]}/>
                    </Map>
                </YMaps>
            </div>
        </div>
    )
}