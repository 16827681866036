import styles from "./styles.module.scss";
import {SVGSelector} from "../../helpers/SVGSelector";
import {Link} from "react-router-dom";

export function MainPage() {

    return (
        <div className={styles.wrapper}>
            <SVGSelector className={styles.logo} id={"logo_2"} color={"#fff"}/>
                <h1 className={styles.mainHeader}>
                    <span className={styles.header}>Невские горизонты</span>
                    <span className={styles.description}>19-20 апреля 2024</span>
                </h1>
        </div>
    )
}