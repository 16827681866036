import styles from "./styles.module.scss";
import {useEffect, useState} from "react";
import 'react-tabs/style/react-tabs.css';

export function Broadcast(props) {
    useEffect(() => {
        window.initProofixWidgetsController({
            companyCode: "nevskiegorizonty",
            eventCode: "event1",
            eventId: 1774,
            isEmbed: true,
        })
    }, []);
    return (
        <div className={styles.wrapper}>
            <h2>Видеотрансляция</h2>
            <div className={styles.page}>
                <div className={styles.rooms} id="rooms"></div>
            </div>
        </div>
    )
}