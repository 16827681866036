import styles from "./styles.module.scss"
import {Link} from "react-router-dom";


export function OurHistory() {
    return (
        <>
            <div className={styles.wrapper}>
                <h2 className={styles.header}>ДОРОГИЕ КОЛЛЕГИ!</h2>
                <p className={styles.paragraph}>
                    Приглашаем вас принять участие во Всероссийской научной конференции
                    офтальмологов с международным участием "Невские горизонты - 2024"!
                    Конференция состоится 19-20 апреля 2024г. в конгресс-холле отеля Cosmos
                    Saint-Petersburg Pulkovskaya по адресу г. Санкт-Петербург, пл. Победы, д. 1.
                </p>
                <p className={styles.paragraph}>
                    Начало конференции 19.04.2024г. в 9.00<br/>
                    <span className={styles.color}>Вход свободный</span><br/>
                </p>
                <p className={`${styles.paragraph} ${styles.paragraph_90} ${styles.color}`}>
                    Документация по данному учебному мероприятию представлена в
                    комиссию по оценке учебных мероприятий и материалов для НМО. Выдача
                    свидетельства НМО возможна после очного посещения 2 дней мероприятия.
                </p>
                <span className={styles.sign}>
                    С уважением и надеждой на скорую встречу на берегах Невы,
                    <span>Оргкомитет.</span>
                </span>

                <Link className={styles.button} to={'/registration'}>
                    Зарегистрироваться
                </Link>
            </div>

        </>
    )
}