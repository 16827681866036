import './App.scss';
import {Header} from "./components/Header/Header";
import '@fontsource/roboto/400.css';
import {Main} from "./components/Main/Main";
import {Route, Routes, useLocation} from "react-router-dom";
import {Program} from "./components/Program/Program";
import {Photos} from "./components/Photos/Photos";
import {PosterSession} from "./components/PosterSession/PosterSession";
import {Error404} from "./errors/Error404/Error404";
import {Auth} from "./components/User/Auth/Auth";
import {Register} from "./components/User/Register/Register";
import {Footer} from "./components/Footer/Footer";
import {useEffect, useState} from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {ResetPassword} from "./components/User/ResetPassword/ResetPassword";
import axios from "axios";
import {Broadcast} from "./components/Broadcast/Broadcast";
import {getHostUrl} from "./helpers/environment";
import {WithSupportOf} from "./components/Main/WithSupportOf/WithSupportOf";

axios.defaults.baseURL = getHostUrl();

function App() {
    const location = useLocation();
    const [scroll, setScroll] = useState(0);
    const [user, setUser] = useState(null);

    const getMe = () => {
        axios.post(`/api/auth/me`, {}, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("BearerToken") || ""
            }
        })
            .then(function (response) {
                setUser(response.data)
            })
            .catch(function (error) {
            })
            .finally(function () {
                // always executed
            });
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (location.hash) {
            document.querySelector(location.hash).scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [location.hash]);

    useEffect(() => {
        getMe();
    }, [location]);

    const scrollToUp = () => {
        document.querySelector("body").scrollIntoView({
            behavior: 'smooth'
        });
    }

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <div className="app">
            <Header user={user}/>
            <div className="wrapper">
                <Routes>
                    <Route path={'/'} element={<Main/>}/>
                    <Route path={'/program'} element={<Program/>}/>
                    <Route path={'/posterSession'} element={<PosterSession/>}/>
                    <Route path={'/broadcast'} element={<Broadcast/>}/>
                    <Route path={'/photos/:photoId/:title'} element={<Photos/>}/>
                    <Route path={'/photos'} element={<Photos/>}/>
                    <Route path={'/auth'} element={<Auth/>}/>
                    <Route path={'/registration'} element={<Register/>}/>
                    <Route path={'/reset'} element={<ResetPassword/>}/>
                    <Route path={'/partners'} element={<WithSupportOf/>}/>
                    <Route path={'*'} element={<Error404/>}/>
                </Routes>
            </div>
            <button className={`${scroll > window.innerHeight ? "show" : ""} btnScrollTop`} onClick={scrollToUp}>
                <ArrowUpwardIcon/>
            </button>
            <Footer/>
        </div>
    );
}

export default App;
