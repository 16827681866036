import logo from "../assets/images/svg/logo.js"
import image from "../assets/images/svg/logo_2";
import asterisk from "../assets/images/svg/asterisk";
import arrowButtonLeft from "../assets/images/svg/arrowButtonLeft";
import arrowLeft from "../assets/images/svg/arrowLeft";
import arrowRight from "../assets/images/svg/arrowRight";

export const SVGSelector = ({id, color, className}) => {
    color = color ? color : "#000";
    switch (id) {
        case 'logo':
            return logo(color,className);
        case 'logo_2':
            return image(color, className);
        case 'asterisk':
            return asterisk(color, className);
            case 'arrow-button-left':
            return arrowButtonLeft(color, className);
            case 'arrow-left':
            return arrowLeft(color, className);
            case 'arrow-right':
            return arrowRight(color, className);
        default:
            break;
    }
}