import styles from "./styles.module.scss";
import image1 from "../../../assets/images/png/partners/1.png"
import image2 from "../../../assets/images/png/partners/2.png"
import image3 from "../../../assets/images/png/partners/3.jpg"
import {useEffect, useState} from "react";
import axios from "axios";
import {getHostUrl} from "../../../helpers/environment";
import {Box, CircularProgress} from "@mui/material";

export function WithSupportOf() {
    let [partners, setPartners] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const hostUrl = getHostUrl() + '/storage/';
    useEffect(() => {
        axios.get(`api/partners/getAll`)
            .then(function (response) {
                setPartners(response.data.sort((a,b)=>{
                    return a.order - b.order;
                }));
            })
            .catch(function (error) {
            }).finally(() => {
            setIsLoading(false)
        });

    }, [])
    return (
        <div className={styles.wrapper}>
            <h2>НАШИ ПАРТНЕРЫ</h2>
            {
                isLoading ?
                    <Box sx={{width: '100%', display:'flex',justifyContent:'center'}}>
                        <CircularProgress />
                    </Box> :
                    <div className={styles.grid}>
                        {partners.map(e => <img src={`${hostUrl}${e.src}`} alt=""/>)}
                    </div>

            }
            <h2>ИНФОРМАЦИОННЫЕ ПАРТНЕРЫ</h2>
            <div className={styles.infoPartners}>
                <div className={styles.firstPartner}>
                    <img src={image1} alt=""/>
                    <p>
                        <span>Офтальмологический портал</span>
                        <span>«Орган зрения»</span>
                        <a href="https://www.video.organum-visus.ru">www.video.organum-visus.ru</a>
                    </p>
                </div>
                <img className={styles.eye} src={image2} alt=""/>
            </div>
            <h2>ТЕХНИЧЕСКИЙ ПАРТНЕР</h2>
            <img src={image3} alt=""/>
        </div>
    )
}