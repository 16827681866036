export default (color, className) => (
    <svg className={className} width="616" height="117" viewBox="0 0 616 117" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M193.8 78.6001C193.6 79.4001 193.5 80.1001 193.3 80.9001H96.7C96.5 80.2001 96.3 79.4001 96.2 78.6001H193.8ZM190.9 87.9001C190.7 88.4001 190.5 88.9001 190.2 89.4001H99.7C99.5 88.9001 99.3 88.4001 99 87.9001H190.9ZM185.4 97.5001C185.1 97.9001 184.9 98.2001 184.6 98.6001H105.4C105.1 98.2001 104.9 97.9001 104.6 97.5001H185.4ZM175.8 107.3C175.5 107.5 175.2 107.7 174.9 108H115.1C114.8 107.8 114.5 107.6 114.2 107.3H175.8ZM156.1 116.5C155.4 116.6 154.8 116.8 154.1 116.9H135.7C135 116.8 134.4 116.6 133.7 116.5H156.1Z"
              fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M145 20.5C172.5 20.5 194.9 42.8 194.9 70.4C194.9 71 194.9 71.6 194.9 72.2H95.1999C95.1999 71.6 95.1999 71 95.1999 70.4C95.0999 42.9 117.5 20.5 145 20.5Z"
              fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.0999756 72.2001H24.3C45 34.6001 83 7.70005 127.7 1.80005V0.300049C116.8 0.400049 96.6 5.80005 87 9.10005C68.3 15.5001 53.2 23.7 38 34.8C33.4 38.1 27.7 42.4 24 46C20 49.4 3.39998 65.9001 0.0999756 72.2001ZM291.6 72.2001H267.8C247.1 34.5001 208.9 7.50005 164 1.80005V0.300049C174.9 0.400049 195.1 5.80005 204.7 9.10005C223.4 15.5001 238.5 23.7 253.7 34.8C258.3 38.1 264 42.4 267.7 46C271.7 49.4 288.3 65.9001 291.6 72.2001Z"
              fill={color}/>
        <path
            d="M345.7 31.6L345.8 31.4C346.4 31.5 347.8 31.6 348.4 31.6C349 31.6 350.5 31.5 351.1 31.4L351.2 31.6C350.8 34.3 350.7 38.1 350.7 41.9V54.5C350.7 58.4 350.8 61.9 351.2 64.8L351.1 65C350.5 64.9 349.1 64.8 348.5 64.8C347.9 64.8 346.4 64.9 345.8 65L345.7 64.8C346.1 62 346.2 58.3 346.2 54.5V48.3H328.3V54.5C328.3 58.4 328.4 61.9 328.8 64.8L328.7 65C328.1 64.9 326.7 64.8 326.1 64.8C325.5 64.8 324 64.9 323.4 65L323.3 64.8C323.7 62 323.8 58.3 323.8 54.5V42C323.8 38.1 323.7 34.6 323.3 31.7L323.4 31.5C324 31.6 325.4 31.7 326 31.7C326.6 31.7 328.1 31.6 328.7 31.5L328.8 31.7C328.4 34.4 328.3 38.2 328.3 42V46.1H346.2V42C346.2 38.1 346.1 34.6 345.7 31.6Z"
            fill={color}/>
        <path
            d="M377.9 62C384.8 62 392.5 61.4 392.5 61.4L392.6 61.6C392.5 62 392.5 62.8 392.5 63.3C392.5 63.8 392.6 64.2 392.6 64.9L392.5 65.1C391.7 65 390.5 64.9 389.4 64.9H375.3C373.5 64.9 372.6 65.1 372.6 65.1L372.5 64.9C372.9 62.1 373 58.4 373 54.6V42C373 38.1 372.9 34.6 372.5 31.7L372.6 31.5C372.6 31.5 373.5 31.7 375.2 31.7H388.8C389.9 31.7 391.1 31.6 391.9 31.5L392 31.7C391.9 32.4 391.9 33.2 391.9 33.7C391.9 34.2 392 34.6 392 35L391.9 35.2C391.9 35.2 384.8 34.6 377.8 34.6C377.4 35.8 377.4 41.2 377.4 42V46C383.5 46 385.9 45.7 388.9 45.5L389.1 45.7C389 46.2 388.9 46.9 388.9 47.4C388.9 47.9 389 48.6 389.1 49.1L388.9 49.3C385.3 48.9 379.9 48.8 377.4 48.8V54.8C377.5 55.5 377.5 60.8 377.9 62Z"
            fill={color}/>
        <path
            d="M421.8 31.5C431.4 31.5 433.2 36 433.2 39.5C433.2 42.3 431 45.1 428.4 46.4C432.9 47.7 436.3 50.5 436.3 55.5C436.3 59.9 433.8 65.1 423.5 65.1C418.9 65.1 418.5 64.9 415.3 64.9C413.5 64.9 412.6 65.1 412.6 65.1L412.5 64.9C412.9 62.1 413 58.4 413 54.6V42C413 38.1 412.9 34.6 412.5 31.7L412.6 31.5C412.6 31.5 413.5 31.7 415.2 31.7C417.8 31.6 420 31.5 421.8 31.5ZM417.5 45.8H421.5C425.8 45.8 428.7 44.5 428.7 40.1C428.7 34.8 425 33.7 422.1 33.7C417.9 33.7 417.4 34.4 417.4 36.1V45.8H417.5ZM417.5 60.4C417.5 62.1 418.9 62.9 422.5 62.9C430 62.9 431.2 60 431.2 56.4C431.2 51.8 428.4 48 422.3 48H417.5V60.4Z"
            fill={color}/>
        <path
            d="M482.4 33.5L482.5 33.7C481.9 35.9 481.8 36.8 481.6 39.4L481 39.5C478.9 35.3 475.1 33.4 470.5 33.4C466.3 33.4 459.6 37.2 459.6 47.7C459.6 55.8 464.4 62.8 471 62.8C475.8 62.8 478.9 61.5 482.6 57.4L483 57.6C483.2 58.1 483.3 59.2 483.3 59.8C479.8 64 475.4 65.5 470.6 65.5C459.5 65.5 454.4 57.7 454.4 48.7C454.4 38.5 462.6 31 471.4 31C477.4 31 482.4 33.5 482.4 33.5Z"
            fill={color}/>
        <path
            d="M503 31.6001L503.1 31.4001C503.7 31.5001 505.1 31.6001 505.7 31.6001C506.3 31.6001 507.7 31.5001 508.3 31.4001L508.4 31.6001C508 34.3001 507.9 38.1001 507.9 41.9001V46.4001C512.6 46.3001 512.8 42.0001 516 37.4001C518.2 34.2001 521.3 30.8 525.3 30.8C526.7 30.8 526.9 31 528.2 31.3L528.5 31.6001L527 36.3L526.4 36.5001C525.4 35.8001 524.5 35.4001 523 35.4001C521.8 35.4001 519.9 36.4001 518.1 38.9001C515.8 42.0001 515 46.2 512.8 47.3V47.5001C516.5 48.1001 517.6 49.6001 520.2 52.7001C524.6 57.7001 524.5 60.3001 528.8 64.9001L528.6 65.1001C527.9 65.0001 525.9 64.9001 524.6 64.9001C523.3 64.9001 523.3 65.0001 522.1 65.1001C519.6 61.1001 518.6 58.9001 517.1 56.2001C514.9 52.2001 513.4 49.2 507.7 48.8V54.6001C507.7 58.5001 507.8 62.0001 508.2 64.9001L508.1 65.1001C507.5 65.0001 506.1 64.9001 505.5 64.9001C504.9 64.9001 503.5 65.0001 502.9 65.1001L502.8 64.9001C503.2 62.1001 503.3 58.4001 503.3 54.6001V42.0001C503.5 38.0001 503.4 34.5001 503 31.6001Z"
            fill={color}/>
        <path
            d="M546.6 31.6L546.7 31.4C547.3 31.5 548.7 31.6 549.3 31.6C549.9 31.6 551.3 31.5 551.9 31.4L552 31.6C551.6 34.3 551.5 38.1 551.5 41.9V54.5C551.5 56.8 551.5 59 551.7 61L569 31.6L569.1 31.4C569.7 31.5 571.1 31.6 571.7 31.6C572.3 31.6 573.7 31.5 574.3 31.4L574.4 31.6C574 34.3 573.9 38.1 573.9 41.9V54.5C573.9 58.4 574 61.9 574.4 64.8L574.3 65C573.7 64.9 572.3 64.8 571.7 64.8C571.1 64.8 569.7 64.9 569.1 65L569 64.8C569.4 62 569.5 58.3 569.5 54.5V42C569.5 39.7 569.5 37.6 569.3 35.6L552 65L551.9 65.2C551.3 65.1 549.9 65 549.3 65C548.7 65 547.3 65.1 546.7 65.2L546.6 65C547 62.2 547.1 58.5 547.1 54.7V42C547.1 38.1 547 34.6 546.6 31.6Z"
            fill={color}/>
        <path
            d="M600.6 62C607.5 62 615.2 61.4 615.2 61.4L615.3 61.6C615.2 62 615.2 62.8 615.2 63.3C615.2 63.8 615.3 64.2 615.3 64.9L615.2 65.1C614.4 65 613.2 64.9 612.1 64.9H598C596.2 64.9 595.3 65.1 595.3 65.1L595.2 64.9C595.6 62.1 595.7 58.4 595.7 54.6V42C595.7 38.1 595.6 34.6 595.2 31.7L595.3 31.5C595.3 31.5 596.2 31.7 597.9 31.7H611.5C612.6 31.7 613.8 31.6 614.6 31.5L614.7 31.7C614.6 32.4 614.6 33.2 614.6 33.7C614.6 34.2 614.7 34.6 614.7 35L614.6 35.2C614.6 35.2 607.5 34.6 600.5 34.6C600.1 35.8 600.1 41.2 600.1 42V46C606.2 46 608.6 45.7 611.6 45.5L611.8 45.7C611.7 46.2 611.6 46.9 611.6 47.4C611.6 47.9 611.7 48.6 611.8 49.1L611.6 49.3C608 48.9 602.6 48.8 600.1 48.8V54.8C600.2 55.5 600.2 60.8 600.6 62Z"
            fill={color}/>
        <path
            d="M328 102.5C328 106.4 328.1 110.1 328.5 112.8L328.4 113C328.4 113 327.5 112.8 325.7 112.8C323.9 112.8 323 113 323 113L322.9 112.8C323.3 109.9 323.5 106.3 323.5 102.5V89.9C323.5 86 323.4 82.4 323 79.6L323.1 79.4C323.1 79.4 324 79.6 325.8 79.6H339.9C341 79.6 342.2 79.5 343 79.4L343.1 79.6C343 80.3 343 80.6 343 81.1C343 81.6 343.1 82.4 343.1 82.8L343 83C343 83 335.3 82.4 328.4 82.4C328 83.6 328 89.1 328 89.9V102.5Z"
            fill={color}/>
        <path
            d="M362.4 113.3C353.5 113.3 346 107 346 96.8C346 86.7 353.1 78.8 362.3 78.8C371.6 78.8 378.9 85.6 378.9 95.8C379 106.2 372.3 113.3 362.4 113.3ZM351.3 95.9001C351.3 105.8 357.2 111 363.4 111C369.3 111 373.6 106 373.6 96.8C373.6 86.1 368.9 81.1001 361.7 81.1001C356.7 81.1001 351.3 85.1001 351.3 95.9001Z"
            fill={color}/>
        <path
            d="M386.5 79.5001L386.6 79.3001C387.2 79.4001 388.6 79.5001 389.2 79.5001C390.8 79.5001 395.1 79.1001 396.4 79.1001C406 79.1001 408.4 84.4001 408.4 88.2001C408.4 92.6001 405.1 98.5001 395.7 98.5001C393.9 98.5001 392.5 98.3001 391.4 98.0001V102.4C391.4 106.3 391.5 109.8 391.9 112.7L391.8 112.9C391.2 112.8 389.8 112.7 389.2 112.7C388.6 112.7 387.2 112.8 386.6 112.9L386.5 112.7C386.9 109.9 387 106.2 387 102.4V89.8001C387 86.0001 386.9 82.5001 386.5 79.5001ZM391.5 95.8001C392.2 96.2001 394.3 96.3001 395.6 96.3001C400.6 96.3001 403.5 94.4001 403.5 88.5001C403.5 82.4001 399.7 81.4001 396.2 81.4001C392.5 81.4001 391.5 82.2001 391.5 83.7001V95.8001Z"
            fill={color}/>
        <path
            d="M415.4 79.5001L415.5 79.3C416.1 79.4 417.5 79.5001 418.1 79.5001C418.7 79.5001 420.1 79.4 420.7 79.3L420.8 79.5001C420.4 82.2001 420.3 86 420.3 89.8V102.4C420.3 104.7 420.3 106.9 420.5 108.9L437.8 79.5001L437.9 79.3C438.5 79.4 439.9 79.5001 440.5 79.5001C441.1 79.5001 442.5 79.4 443.1 79.3L443.2 79.5001C442.8 82.2001 442.7 86 442.7 89.8V102.4C442.7 106.3 442.8 109.8 443.2 112.7L443.1 112.9C442.5 112.8 441.1 112.7 440.5 112.7C439.9 112.7 438.5 112.8 437.9 112.9L437.8 112.7C438.2 109.9 438.3 106.2 438.3 102.4V89.8C438.3 87.5 438.3 85.4001 438.1 83.4001L420.8 112.8L420.7 113C420.1 112.9 418.7 112.8 418.1 112.8C417.5 112.8 416.1 112.9 415.5 113L415.4 112.8C415.8 110 415.9 106.3 415.9 102.5V89.9001C415.9 86.0001 415.8 82.5001 415.4 79.5001Z"
            fill={color}/>
        <path
            d="M469.7 103.7C469.7 101.1 467.7 95.4001 461.6 95.4001H457.8C457.2 95.4001 456.9 95.0001 456.9 94.5001C456.9 93.9001 457.3 93.5001 457.8 93.5001H460.2C466.4 93.5001 468.1 89.5001 468.1 86.6001C468.1 83.1001 464.8 80.5001 461.6 80.5001C455.2 80.5001 454.1 84.3001 452.8 88.5001L451.2 88.4001L452.2 79.4001C456.7 79.4001 458.5 78.8 461.6 78.8C466.4 78.8 472.1 81.2001 472.1 86.1001C472.1 90.2001 469.9 92.8001 466.2 94.1001C469.9 95.1001 474.3 97.2 474.3 102.8C474.3 110.6 467.4 113.3 461.3 113.3C457.1 113.3 454.6 111.7 453 110.5C451.6 109.4 450.5 108 450.5 106.6C450.5 105.3 451.1 104.2 452.3 104.2C453.7 104.2 454.3 104.9 454.8 106.5C455.9 109.6 457.5 111.4 461.5 111.4C465.2 111.5 469.7 109.3 469.7 103.7Z"
            fill={color}/>
        <path
            d="M496.5 113.3C487.6 113.3 480.1 107 480.1 96.8C480.1 86.7 487.2 78.8 496.4 78.8C505.7 78.8 513 85.6 513 95.8C513 106.2 506.3 113.3 496.5 113.3ZM485.4 95.9001C485.4 105.8 491.3 111 497.5 111C503.4 111 507.7 106 507.7 96.8C507.7 86.1 503 81.1001 495.8 81.1001C490.8 81.1001 485.4 85.1001 485.4 95.9001Z"
            fill={color}/>
        <path
            d="M541.7 79.5001L541.8 79.3C542.4 79.4 543.8 79.5001 544.4 79.5001C545 79.5001 546.5 79.4 547.1 79.3L547.2 79.5001C546.8 82.2001 546.7 86 546.7 89.8V102.4C546.7 106.3 546.8 109.8 547.2 112.7L547.1 112.9C546.5 112.8 545.1 112.7 544.5 112.7C543.9 112.7 542.4 112.8 541.8 112.9L541.7 112.7C542.1 109.9 542.2 106.2 542.2 102.4V96.2001H524.3V102.4C524.3 106.3 524.4 109.8 524.8 112.7L524.7 112.9C524.1 112.8 522.7 112.7 522.1 112.7C521.5 112.7 520 112.8 519.4 112.9L519.3 112.7C519.7 109.9 519.8 106.2 519.8 102.4V89.8C519.8 85.9 519.7 82.4001 519.3 79.5001L519.4 79.3C520 79.4 521.4 79.5001 522 79.5001C522.6 79.5001 524.1 79.4 524.7 79.3L524.8 79.5001C524.4 82.2001 524.3 86 524.3 89.8V93.9001H542.2V89.8C542.2 86 542.1 82.5001 541.7 79.5001Z"
            fill={color}/>
        <path
            d="M563.4 82.4001C556.5 82.4001 553 83.1001 553 83.1001L552.9 82.9001C553 82.1001 553 80.9001 552.9 79.5001L553 79.3C553.8 79.4 555 79.5001 556.1 79.5001H576.1C577.2 79.5001 578.4 79.4 579.2 79.3L579.3 79.5001C579.2 80.9001 579.2 82.1001 579.3 82.9001L579.2 83.1001C579.2 83.1001 575.7 82.4001 568.8 82.4001C568.4 83.6001 568.4 89 568.4 89.8V102.4C568.4 106.3 568.5 109.8 568.9 112.7L568.8 112.9C568.2 112.8 566.8 112.7 566.2 112.7C565.6 112.7 564.2 112.8 563.6 112.9L563.5 112.7C563.9 109.9 564 106.2 564 102.4V89.8C563.8 89 563.8 83.7001 563.4 82.4001Z"
            fill={color}/>
        <path
            d="M584.8 113L584.7 112.8C585.1 110 585.2 106.3 585.2 102.5V89.9001C585.2 86.0001 585 82.5001 584.6 79.6001L584.7 79.4001C584.7 79.4001 585.6 79.6001 587.4 79.6001C589.2 79.6001 590.1 79.4001 590.1 79.4001L590.2 79.6001C589.8 82.3001 589.7 86.1001 589.7 89.9001V93.6001H593.7C606.4 93.6001 607.2 98.8001 607.2 103.4C607.2 112.1 600.3 112.8 592.8 112.9C590.9 112.9 589 112.8 587.5 112.8C585.9 112.8 584.8 113 584.8 113ZM589.8 108.5C589.8 110.2 589.9 111.1 592.7 111.1C600.6 111.1 602.1 107.1 602.1 103.4C602.1 99.9001 600.8 95.9001 594.1 95.9001H589.7V108.5H589.8ZM610.3 79.5001L610.4 79.3C611 79.4 612.4 79.5001 613 79.5001C613.6 79.5001 615 79.4 615.6 79.3L615.7 79.5001C615.3 82.2001 615.2 86 615.2 89.8V102.4C615.2 106.3 615.3 109.8 615.7 112.7L615.6 112.9C615 112.8 613.6 112.7 613 112.7C612.4 112.7 611 112.8 610.4 112.9L610.3 112.7C610.7 109.9 610.8 106.2 610.8 102.4V89.8C610.8 85.9 610.7 82.4001 610.3 79.5001Z"
            fill={color}/>
    </svg>
)