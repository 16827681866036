import styles from './styles.module.scss';
import {useEffect, useState} from "react";
import Lightbox from "react-18-image-lightbox";
import axios from "axios";
import {parseError} from "../../helpers/parseErrors";
import {ErrorHelper} from "../../helpers/ErrorHelper";
import {EmptyPage} from "../EmptyPage/EmptyPage";
import {useLocation, useParams} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {getHostUrl} from "../../helpers/environment";

export function Photos() {
    const [index, setIndex] = useState(-1);
    const [loaded, setLoaded] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [photos, setPhotos] = useState([])
    const {photoId, title} = useParams()
    const location = useLocation();

    let hostUrl = getHostUrl();

    useEffect(() => {
        if(photoId){
            setLoadPage(true);
            axios.get(`/api/photos/getPhotos/${photoId}`)
                .then(function (response) {
                    setPhotos(response.data);
                })
                .catch(function (error) {
                    setOpen(true)
                    setType("error")
                    setMessage(`Произошла ошибка:\n ${parseError(error.response.data)}`)
                }).finally(() => {
                setLoadPage(false);
            });
        }
    }, [location])

    useEffect(() => {
        if (photos[index]) {
            let image = new Image();
            image.src = photos[index];

            image.onload = () => {
                setLoaded(true);
            }
        }
    }, [index, photos]);

    const handleClick = (index) => setIndex(index);
    const handleClose = () => {
        setIndex(-1);
        setLoaded(false)
    };
    const handleMovePrev = () => setIndex((index + photos.length - 1) % photos.length);
    const handleMoveNext = () => setIndex((index + 1) % photos.length);

    return (
        loadPage ?
            <Box sx={{display: 'flex', width: "100%", height: "100vh", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress/>
            </Box>
            :
            <>
                <h2>{title || 'Фотоотчет'}</h2>
                <div className={styles.wrapper}>
                    {photos.length ?
                        <div className={styles.holder}>
                        <div className={styles.grid}>
                            {photos.map((e, i) => <div className={styles.item} onClick={() => handleClick(i)}><img
                                key={i}
                                src={`${hostUrl}//storage/${e.src}`}
                                alt=""/>
                            </div>)}
                        </div>
                        </div>
                        :
                        <EmptyPage/>}
                    {!!photos[index]?.src && (
                        <Lightbox
                            mainSrc={`${hostUrl}//storage/` + photos[index].src}
                            mainSrcThumbnail={`${hostUrl}//storage/` + photos[index].src}
                            nextSrc={`${hostUrl}//storage/` + photos[(index + photos.length - 1) % photos.length].src || `${process.env.REACT_APP_API}//storage/` + photos[index].src}
                            nextSrcThumbnail={`${hostUrl}//storage/` + photos[(index + photos.length - 1) % photos.length].src || `${process.env.REACT_APP_API}//storage/` + photos[index].src}
                            prevSrc={`${hostUrl}//storage/` + photos[(index + 1) % photos.length].src || `${process.env.REACT_APP_API}//storage/` + photos[index].src}
                            prevSrcThumbnail={`${hostUrl}//storage/` + photos[(index + 1) % photos.length].src || `${process.env.REACT_APP_API}//storage/` + photos[index].src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                    <ErrorHelper type={type} message={message} open={open} setOpen={setOpen}/>
                </div>
            </>
    )
}