import styles from './styles.module.scss';
import {Document, Page, pdfjs} from "react-pdf";
import {useEffect, useRef, useState} from "react";
import {SVGSelector} from "../../helpers/SVGSelector";
import "./document-styles.scss";
import axios from "axios";
import {getHostUrl} from "../../helpers/environment";
import {Box, LinearProgress} from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export function Program() {
    const [programUrl, setProgramUrl] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [transformX, setTransformX] = useState(0);
    const [maxLengthSlider, setMaxLengthSlider] = useState(0);
    const [maxLengthScrollSlider, setMaxLengthScrollSlider] = useState(1);
    const [slideIndex, setSlideIndex] = useState(1);
    const [maxVisiblePages, setMaxPages] = useState(2);
    const [isLoading, setIsLoading] = useState(true);
    const docRef = useRef();
    const hostUrl = getHostUrl();

    useEffect(() => {
        if (window.innerHeight <= 1240) {
            setMaxPages(1);
        }

        axios.get(`api/program/getAll`)
            .then(function (response) {
                if (response.data && response.data[0]?.src) {
                    setProgramUrl(hostUrl + '/storage/' + response.data[0]?.src);
                }
            })
            .catch(function (error) {
            });

    }, [])

    const onDownload = () => {
        const link = document.createElement("a");
        link.href = programUrl;
        link.download = "Программа.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const changePosition = (next) => {
        const {doc, width} = getDataFromDoc();
        let transform = next ? +transformX + width / maxVisiblePages * maxLengthScrollSlider : +transformX - width / maxVisiblePages * maxLengthScrollSlider;
        doc.style.transform = `translateX(-${transform}px)`;
        setTransformX(+transform)
        setSlideIndex(next ? slideIndex + 1 : slideIndex - 1);
    }

    //private
    const fileLoaded = ({numPages}) => {
        setNumPages(numPages);
        setMaxLengthSlider(Math.round(numPages / maxLengthScrollSlider));
        setIsLoading(false)
    }

    //private
    const getDataFromDoc = () => {
        let doc = docRef.current.querySelector(".react-pdf__Document");
        const widthPx = window.getComputedStyle(doc).width;
        const width = typeof widthPx === "string" && +widthPx.slice(0, -2);
        return {doc, width};
    }


    return (
        <div className={styles.program}>
            <h2>
                Программа конференции 2024
                <button className={styles.button} onClick={onDownload}>Скачать <SVGSelector className={styles.svg}
                                                                                            id={"arrow-button-left"}/>
                </button>
            </h2>
            <div className={styles.wrapper}>
                <button style={{visibility: slideIndex - maxVisiblePages >= 0 ? "visible" : "hidden"}}
                        className={styles.click_button} onClick={() => changePosition()}><SVGSelector
                    className={styles.svg} id={"arrow-left"}/></button>
                <div className={styles.document_wrapper} ref={docRef}>
                    {isLoading &&
                        <Box sx={{width: '100%'}}>
                            <LinearProgress />
                        </Box>
                    }
                    <Document file={programUrl}
                              onLoadSuccess={fileLoaded}>
                        {Array.from(Array(numPages).keys()).map(e => <Page pageNumber={e + 1}/>)}
                    </Document>
                </div>

                <button style={{visibility: maxLengthSlider > slideIndex ? "visible" : "hidden"}}
                        className={styles.click_button} onClick={() => changePosition(true)}><SVGSelector
                    className={styles.svg} id={"arrow-right"}/></button>
            </div>
        </div>
    )
}