import {HeaderElement} from "./HeaderElement/HeaderElement";
import styles from "./styles.module.scss"
import {useLocation} from "react-router-dom"
import {useEffect, useState, Fragment} from "react";
import {SVGSelector} from "../../helpers/SVGSelector";
import {
    Box,
    Button, Collapse,
    Drawer, LinearProgress,
    List,
    ListItem,
    ListItemButton, Menu, MenuItem
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import axios from "axios";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export function Header({user}) {
    let anchor = 'right';
    const [state, setState] = useState(false);
    const [listLinks, setListLinks] = useState([]);
    const location = useLocation();
    const [loader, setLoader] = useState(true);

    const handleDesktopClose = (obj) => {
        obj.anchorEl = null;
        setListLinks([...listLinks]);
    };
    const handleDesktopClick = (event, obj) => {
        obj.anchorEl = event.currentTarget;
        setListLinks([...listLinks]);
    };

    const handleClick = (element) => {
        element.open = !element.open;
        setListLinks([...listLinks]);
    };

    useEffect(() => {
        axios.get('api/links/getAll').then(({data}) => {
            let list = data.filter(e => !!e.visibility).map(e => {
                if (e.text === "Трансляция") {
                    e.link = user ? `/broadcast` : `/auth`
                    e.children = [];
                }

                if (e.text === "Фотоотчет" && e.children?.length) {
                    e.children = e.children.map(data => ({text: data.title, link: `/photos/${data.id}/${data.title}`}))
                    e.link = ""
                }
                return e;
            })

            if (user) {
                list.push({text: user?.first_name || '', link: "/"});
            } else {
                list.push(...[{text: "Регистрация", link: "/registration"}, {text: "Авторизация", link: "/auth"}])
            }
            setListLinks(list);
        }).finally(() => {
            setLoader(false)
        })
    }, [user]);


    const toggleDrawer = (status) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift') || event.target.tagName === "SPAN") {
            return;
        }

        setState(status);
    };
    const list = () => (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {listLinks.map((obj, index) => (
                    <ListItem key={index} disablePadding className={styles.listItem}>
                        <ListItemButton className={styles.listButton}
                                        onClick={obj.children?.length ? () => handleClick(obj) : () => {
                                        }}>
                            <HeaderElement className={styles.menu_link} text={obj.text}
                                           link={obj.link} anotherLink={obj.anotherLink}/>
                            {obj.children?.length ? obj.open ? <ExpandLess/> : <ExpandMore/> : null}
                        </ListItemButton>
                        {obj.children?.length ?
                            <Collapse in={obj.open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {obj.children?.map((e, index) =>
                                        <ListItemButton key={index} sx={{pl: 4}}>
                                            <HeaderElement className={styles.menu_link} text={e.text}
                                                           link={e.link} anotherLink={e.anotherLink}/>
                                        </ListItemButton>
                                    )}
                                </List>
                            </Collapse>
                            :
                            null
                        }
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    let logo = () => {
        if (location.pathname === "/") {
            return <SVGSelector id={"logo"} color={"#0062a6"} className={styles.logo}/>
        } else {
            return <HeaderElement className={styles.logo} link={"/"}>
                <SVGSelector id={"logo"} color={"#0062a6"}/>
            </HeaderElement>
        }
    }


    return (
        <>
            <div className={styles.mobileNav}>
                {logo()}
                <Button onClick={toggleDrawer(true)}><MenuIcon/></Button>
                <Drawer
                    PaperProps={{
                        sx: {width: "100%"}
                    }}
                    anchor={anchor}
                    open={state}
                    onClose={toggleDrawer(false)}
                >
                    {!loader && list()}
                </Drawer>
            </div>

            <nav className={styles.nav}>
                {logo()}
                {!loader ? listLinks.map((obj, i) =>
                        <Fragment key={i}>
                            <HeaderElement className={styles.menu_link} text={obj.text}
                                           link={obj.children?.length ? null : obj.link}
                                           anotherLink={obj.anotherLink}
                                           onMouseEnter={(event) => handleDesktopClick(event, obj)}/>
                            {obj.children?.length ?
                                <Menu
                                    id="basic-menu"
                                    anchorEl={obj.anchorEl}
                                    open={!!obj.anchorEl}
                                    onClose={() => handleDesktopClose(obj)}
                                    MenuListProps={{onMouseLeave: () => handleDesktopClose(obj)}}

                                >
                                    {obj.children.map((e, i) => <MenuItem key={i} onClick={() => handleDesktopClose(obj)}>
                                        <HeaderElement className={styles.menu_link} text={e.text}
                                                       link={e.link} anotherLink={e.anotherLink}/>
                                    </MenuItem>)}
                                </Menu>
                                :
                                null
                            }
                        </Fragment>
                    )
                    :
                    <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>
                }
            </nav>
        </>
    )
}