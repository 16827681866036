import styles from "./styles.module.scss";

export function Hotel() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                <h2>ГОСТИНИЧНОЕ РАЗМЕЩЕНИЕ</h2>
                <b>Уважаемые коллеги!</b>
                <p>
                    Оргкомитет конференции будет рад оказать содействие в
                    бронировании гостиниц в <span>г. Санкт-Петербурге.</span>
                </p>
                <p className={styles.contacts}>
                    Екатерина Шахова
                    <a href="tel:+79213272545">+7 921 327-25-45</a>
                    <a className={styles.mail} href="mailto:office@optimatours.ru">office@optimatours.ru</a>
                </p>
            </div>
        </div>
    )
}