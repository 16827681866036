import {Welcome} from "./Welcome/Welcome";
import {Hotel} from "./Hotel/Hotel";
import {OurHistory} from "../OurHistory/OurHistory";
import {AboutUs} from "../AboutUs/AboutUs";
import {MainPage} from "../MainPage/MainPage";

export function Main() {
    return (
        <>
            <MainPage/>
            <OurHistory/>
            <AboutUs/>
            <Welcome/>
            <Hotel/>
        </>
    )
}