import styles from "./styles.module.scss"
import {useState} from "react";
import axios from "axios";
import {Alert, Button, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {parseError} from "../../../helpers/parseErrors";
import {useErrorsForm} from "../../../helpers/errorsForm";
import {ErrorHelper} from "../../../helpers/ErrorHelper";

export function ResetPassword(props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    let [errors, setErrors, removeOneError] = useErrorsForm();
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleChangeEmail = event => {
        if (!isValidEmail(event.target.value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        setEmail(event.target.value);
    };
    const sendReset = () => {
        axios.post(`/api/auth/reset`, {
            email,
        })
            .then(function (response) {
                setOpen(true)
                setType("success")
                setMessage("Письмо с паролем выслано на почту")
                setTimeout(() => {
                    navigate("/auth");
                }, 3000)
            })
            .catch(function (error) {
                setOpen(true)
                setType("error")
                setMessage(`Произошла ошибка:\n ${parseError(error.response.data)}`)
                setErrors(error.response.data.errors)
            })
            .finally(function () {
                // always executed
            });
    }

    return (
        <div className={styles.auth}>
            <h1 className={styles.header}>Восстановление пароля</h1>
            <div className={styles.list}>
                <div
                    className={`${styles.form__group} ${emailError || errors?.email ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field}
                           type="text" name="email" value={email}
                           onChange={handleChangeEmail}
                           onFocus={() => removeOneError("email")}/>
                    <label className={styles.form__label}>e-mail</label>
                </div>
            </div>
            <Button variant="contained" className={styles.button} onClick={sendReset}>Отправить пароль на почту</Button>
            <ErrorHelper type={type} message={message} open={open} setOpen={setOpen}/>
        </div>
    )
}