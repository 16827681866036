export const parseError = (errors) => {
    let result = '';
    if(typeof errors.message === "object"){
        Object.keys(errors.message).forEach(key=>{
            result += `${key}: ${errors.message[key].join(", ")}\n`
        })
    }else if(typeof errors.message === "string"){
        result = errors.message;
    }else if(errors.error){
        result = errors.error;
    }

    return result;
}