import {Link} from "react-router-dom";
import styles from './style.module.scss';

export function HeaderElement({className, text, link, anotherLink, children, onClick, onMouseEnter, onMouseLeave}) {

    if (anotherLink) {
        return (
            <a className={`${className} ${styles.element}`} href={anotherLink} target="_blank">
                {text}
            </a>
        )
    }

    if (!link) {
        return (
            <span className={`${className} ${styles.element}`} onClick={onClick} onMouseEnter={onMouseEnter} >
                {text}
            </span>
        )
    }

    return (
        <Link className={`${className} ${styles.element}`} to={link}>
            {children || text}
        </Link>
    )
}