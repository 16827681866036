import {useState} from "react";
import {Alert, Snackbar} from "@mui/material";

export const ErrorHelper = ({type,message, open, setOpen}) => {
    return (
        <Snackbar
            open={open}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity={type} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}