import styles from "./styles.module.scss"
import {SVGSelector} from "../../helpers/SVGSelector";

export function AboutUs() {
    return (
        <div className={styles.wrapper}>
            <SVGSelector className={styles.logo} id={"logo_2"} color={"#fff"}/>
            <div>
                <h3>Обсуждаемые вопросы</h3>
                <ul>
                    <li>социально-медицинские аспекты детской и взрослой офтальмологии</li>
                    <li>слепота и слабовидение и меры по борьбе с ними</li>
                    <li>методы диагностики и лечения повреждений органа зрения</li>
                    <li>консервативные и хирургические методы лечения заболеваний и повреждений органа зрения</li>
                    <li>проблемы контроля миопии</li>
                    <li>ретинопатия недоношенных</li>
                    <li>наследственные и дегеративные изменения сетчатки</li>
                    <li>инфекционные и воспалительные заболевания переднего отдела глаза</li>
                    <li>синдром «сухого глаза»</li>
                    <li>глаукома и катаракта у детей и взрослых</li>
                    <li>и многое другое</li>
                </ul>
                <h3>Формы участия в конференции</h3>
                <ul>
                    <li>с устным докладом</li>
                    <li>с электронным стендовым докладом</li>
                    <li>в качестве внимательного слушателя</li>
                </ul>
            </div>
        </div>
    )

}