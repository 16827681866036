import {useState} from "react";

export const useErrorsForm = () => {
    const [errors, setErrors] = useState(null);

    function handleChange(e) {
        setErrors(e);
    }
    function removeOne(key) {
        if(errors){
            delete errors[key];
            setErrors(errors);
        }
    }

    return [errors, handleChange, removeOne];
}