import styles from "./styles.module.scss"
import {useState} from "react";
import axios from "axios";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {parseError} from "../../../helpers/parseErrors";
import {useErrorsForm} from "../../../helpers/errorsForm";
import {ErrorHelper} from "../../../helpers/ErrorHelper";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

export function Register(props) {
    const academicDegreeList = ["Кандидат медицинских наук", "Доктор медицинских наук", "Нет"]
    const academicTitleList = ["Ассистент", "Доцент", "Профессор", "Нет"]
    const formParticipationList = ["Очно докладчик", "Очно слушатель", "Online"]

    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [institution, setInstitution] = useState('');
    const [post, setPost] = useState('');
    const [academicDegree, setAcademicDegree] = useState(academicDegreeList[0]);
    const [academicTitle, setAcademicTitle] = useState(academicTitleList[0]);
    const [formParticipation, setFormParticipation] = useState(formParticipationList[0]);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState(false);
    const [invitationRequired, setInvitationRequired] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    let [errors, setErrors, removeOneError] = useErrorsForm();
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleChangeEmail = event => {
        if (!isValidEmail(event.target.value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        setEmail(event.target.value);
    };
    const handleChangePhone = value => {
        console.log(value);
        if (value.length < 6) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }

        setPhone(value);
    };


    const sendRegister = () => {
        axios.post(`/api/auth/register`, {
            patronymic,
            institution,
            post,
            phone,
            email,
            first_name: firstName,
            last_name: lastName,
            academic_degree: academicDegree,
            academic_title: academicTitle,
            form_participation: formParticipation,
            invitation_required: invitationRequired,
        })
            .then(function (response) {
                setOpen(true)
                setType("success")
                setMessage("Вы успешно зарегистрированы. Письмо с паролем отправлено на почту")
                setTimeout(() => {
                    navigate("/auth");
                }, 3000)
            })
            .catch(function (error) {
                setOpen(true)
                setType("error")
                setMessage(`Произошла ошибка:\n ${parseError(error.response.data)}`)
                setErrors(error.response.data.message)
            })
            .finally(function () {
            });
    }

    return (
        <div className={styles.register}>
            <h1 className={styles.header}>Регистрация</h1>
            <div className={styles.list}>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.last_name ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field} type="text" name="last_name"
                           value={lastName} placeholder={"Иванов"}
                           onChange={(e) => setLastName(e.target.value)}
                           onFocus={() => removeOneError("last_name")}/>
                    <label className={styles.form__label}>Фамилия</label>
                </div>

                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.first_name ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field} type="text" name="first_name"
                           value={firstName} placeholder={"Иван"}
                           onChange={(e) => setFirstName(e.target.value)}
                           onFocus={() => removeOneError("first_name")}/>
                    <label className={styles.form__label}>Имя</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.patronymic ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field} type="text" name="patronymic"
                           value={patronymic} placeholder={"Иванович"}
                           onChange={(e) => setPatronymic(e.target.value)}
                           onFocus={() => removeOneError("patronymic")}/>
                    <label className={styles.form__label}>Отчество</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.institution ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field} type="text" name="institution"
                           value={institution} placeholder={"УЧСОВ Михайловского"}
                           onChange={(e) => setInstitution(e.target.value)}
                           onFocus={() => removeOneError("institution")}/>
                    <label className={styles.form__label}>Учреждение</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.post ? styles.error : ''}`}>
                    <input autocomplete="off" className={styles.form__field} type="text" name="post" value={post}
                           placeholder={"Клинический фармаколог"}
                           onChange={(e) => setPost(e.target.value)}
                           onFocus={() => removeOneError("post")}/>
                    <label className={styles.form__label}>Должность</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.academic_degree ? styles.error : ''}`}>
                    <select className={styles.form__field} name="academic_degree"
                            onChange={(e) => setAcademicDegree(academicDegreeList[e.target.value])}>

                        {academicDegreeList.map((e, i) => <option key={i} value={i}>{e}</option>)}
                    </select>
                    <label className={styles.form__label}>Ученая степень</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.academic_title ? styles.error : ''}`}>
                    <select className={styles.form__field} name="academic_title"
                            onChange={(e) => setAcademicTitle(academicTitleList[e.target.value])}>
                        {academicTitleList.map((e, i) => <option key={i} value={i}>{e}</option>)}
                    </select>
                    <label className={styles.form__label}>Ученое звание</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.form_participation ? styles.error : ''}`}>
                    <select className={styles.form__field} name="form_participation"
                            onChange={(e) => setFormParticipation(formParticipationList[e.target.value])}>
                        {formParticipationList.map((e, i) => <option key={i} value={i}>{e}</option>)}
                    </select>
                    <label className={styles.form__label}>Форма участия</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${errors?.phone || phoneError ? styles.error : ''}`}>
                    <PhoneInput
                        name="phone"
                        country={'ru'}
                        value={phone}
                        onChange={handleChangePhone}
                        className={styles.phone}
                        onFocus={() => removeOneError("phone")}
                    />
                    <label className={styles.form__label}>Контактный телефон</label>
                </div>
                <div
                    className={`${styles.form__group} ${styles.field} ${styles.required} ${emailError || errors?.email ? styles.error : ''}`}>
                    <input className={styles.form__field} type="text" name="email" value={email}
                           placeholder={"aa@gmail.com"}
                           onChange={handleChangeEmail}
                           onFocus={() => removeOneError("email")}/>
                    <label className={styles.form__label}>e-mail</label>
                </div>
                <div className={`${styles.form__group} ${styles.field} ${styles.required} ${styles.radio_group}`}>
                    <label className={styles.form__label}>Требуется приглашение на выбор</label>
                    <label className={styles.radio}>
                        Да
                        <input className={styles.form__field}
                               type="radio"
                               name="invitation_required"
                               checked={!!invitationRequired}
                               onChange={(e) => setInvitationRequired(true)}/>
                    </label>
                    <label className={styles.radio}>
                        Нет
                        <input className={styles.form__field}
                               type="radio"
                               name="invitation_required"
                               checked={!invitationRequired}
                               onChange={(e) => setInvitationRequired(false)}/>
                    </label>
                </div>
            </div>
            <Button variant="contained" className={styles.button} onClick={sendRegister}>Зарегистрироваться</Button>
            <ErrorHelper type={type} message={message} open={open} setOpen={setOpen}/>
        </div>
    )
}