import styles from "./styles.module.scss";
import {useEffect, useState} from "react";
import axios from "axios";
import {parseError} from "../../helpers/parseErrors";
import {ErrorHelper} from "../../helpers/ErrorHelper";
import {EmptyPage} from "../EmptyPage/EmptyPage";
import {SVGSelector} from "../../helpers/SVGSelector";

export function PosterSession() {
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        axios.get(`/api/posterSessionTopic/getAllWithPosters`)
            .then(function (response) {
                setTopics(response.data);
            })
            .catch(function (error) {
                setOpen(true)
                setType("error")
                setMessage(`Произошла ошибка:\n ${parseError(error.response.data)}`)
            });
    }, [])

    return (
        <div className={styles.wrapper}>
            <h2>Постерная сессия</h2>
            <div className={styles.page}>
                {topics.length ? topics.map(topic => topic.poster_sessions.length &&
                        <>
                            <h3>{topic.title}</h3>
                            <div className={styles.grid}>
                                {topic.poster_sessions.map((e, i) =>
                                    <div className={styles.item} key={i}>
                                        <p>{e.theme}</p>
                                        <span>{e.authors}</span>
                                        <a href={e.link} className={styles.button}>Смотреть<SVGSelector className={styles.svg} id={"arrow-button-left"}/></a>
                                    </div>
                                )}
                            </div>
                        </>
                    )
                    :
                    <EmptyPage/>
                }
            </div>
            <ErrorHelper type={type} message={message} open={open} setOpen={setOpen}/>
        </div>
    )
}