export default (color, className) => (
    <svg className={className} version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 841.9 595.3">
	<g id="XMLID_1_">
	<g id="XMLID_43_">
	<path id="XMLID_48_" fill={color} d="M514.8,225.2c-0.3,1.4-0.6,2.9-1,4.3h-183c-0.4-1.4-0.7-2.8-1-4.3H514.8L514.8,225.2z
			M509.3,242.8c-0.4,0.9-0.8,1.9-1.2,2.8H336.6c-0.4-0.9-0.8-1.8-1.2-2.8H509.3L509.3,242.8z M498.9,260.9c-0.5,0.7-1,1.3-1.5,2
			H347.3c-0.5-0.7-1-1.3-1.5-2H498.9L498.9,260.9z M480.6,279.6c-0.6,0.4-1.1,0.8-1.7,1.3H365.7c-0.6-0.4-1.1-0.8-1.7-1.3H480.6
			L480.6,279.6z M443.4,297c-1.2,0.3-2.5,0.5-3.7,0.8h-34.8c-1.3-0.2-2.5-0.5-3.8-0.8H443.4z"/>
        <path id="XMLID_47_" fill={color} d="M422.4,115.2c52.2,0,94.5,42.3,94.5,94.5c0,1.1,0,2.2-0.1,3.4H328c0-1.1-0.1-2.2-0.1-3.4
			C327.9,157.5,370.2,115.2,422.4,115.2z"/>
        <path id="XMLID_44_" fill={color} d="M147.9,213.1l45.8,0c39.2-71.2,111.1-122.2,195.8-133.3l0-2.9c-20.7,0.2-58.9,10.4-77.1,16.7
			c-35.4,12.2-64,27.7-92.8,48.6c-8.6,6.2-19.5,14.4-26.6,21.3C185.6,169.8,154.2,201.1,147.9,213.1L147.9,213.1z M700.1,213.1
			l-45.1,0C615.7,141.7,543.5,90.6,458.5,79.7l0-2.8c20.7,0.2,58.9,10.4,77.1,16.7c35.4,12.2,64,27.7,92.8,48.6
			c8.6,6.2,19.5,14.4,26.6,21.3C662.4,169.8,693.8,201.1,700.1,213.1z"/></g></g>
</svg>
)