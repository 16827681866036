import styles from './styles.module.scss';

export function Footer() {
    return (
        <div className={styles.wrapper}>
            <p className={styles.company}>
                Кафедра и клиника офтальмологии ФГБОУ ВО СПбГПМУ Минздрава России
                 <a href="mailto:info@nevahorizons.ru"> info@nevahorizons.ru</a>
            </p>
        </div>
    )
}